<template>
  <div class="container">
    <div ref="box">
      <div ref="header">
        <a-page-header
          style="border-bottom: 1px solid rgb(235, 237, 240)"
          title="轮播图管理"
        >
          <template slot="extra">
            <a-button class="editable-add-btn" @click="handleAdd" size="small">
              新增
            </a-button>
          </template>
        </a-page-header>
      </div>
      
      <div ref="form">
        <a-form
          :form="form"
          layout="inline"
          @submit="handleSubmit"
          style="padding: 15px 0"
          ref="form"
        >
          <a-form-item label="是否可用">
            <a-select
              placeholder="全部"
              style="width: 180px"
              v-decorator="['status', { rules: [{ required: false }] }]"
            >
              <a-select-option value=""> 全部 </a-select-option>
              <a-select-option value="1"> 可用 </a-select-option>
              <a-select-option value="0"> 不可用 </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" @click="getExport(0)">
              搜索
            </a-button>
          </a-form-item>
        </a-form>
      </div>
      <a-table
        :columns="columns"
        :data-source="posterList"
        :pagination="false"
        :scroll="{ x: 1000, y: table_h }"
        :row-key="(record) => record.id"
        size="middle"
        ref="tableList"
      >
        <img slot="pic" slot-scope="pic" :src="pic" />
        <span slot="status" slot-scope="status">
          <a-icon type="check" v-if="status == 1" style="color: #0092dc" />
          <a-icon type="close" v-if="status == 0" style="color: #ed5565" />
        </span>
        <span slot="btns" slot-scope="btns, num">
          <a-button
            class="editable-add-btn"
            v-for="btn in btns"
            :key="btn"
            @click="handel(btn, num)"
            size="small"
            style="margin: 5px"
          >
            {{ btn }}
          </a-button>
        </span>
      </a-table>
      <!-- <div ref="pagination">
        <a-pagination
          v-if="pageshow"
          :default-current="currentPage"
          :defaultPageSize="defaultPageSize"
          :page-size-options="pageSizeOptions"
          show-size-changer
          show-quick-jumper
          :total="total_num"
          :show-total="(total) => `共 ${total} 条`"
          @showSizeChange="onShowSizeChange"
          @change="onPageChange"
        >
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div> -->
      <a-modal
        v-model="visible"
        :title="modelTitle"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        ok-text="确认"
        cancel-text="取消"
      >
        <a-form :form="addform" @submit="handleSubmit">
          <a-form-item label="轮播图名称：">
            <a-input
              v-decorator="[
                'name',
                {
                  rules: [{ required: true, message: '请输入轮播图名称!' }],
                },
              ]"
            />
          </a-form-item>
          <a-form-item label="轮播图链接：">
            <a-input
              v-decorator="[
                'url',
                {
                  rules: [{ required: true, message: '请输入轮播图链接!' }],
                },
              ]"
            />
          </a-form-item>
          <a-form-item label="图片" extra="最多支持1张，建议尺寸：654px*200px">
            <a-input
              v-decorator="[
                'pic',
                {
                  rules: [{ required: true, message: '请选择图片!' }],
                },
              ]"
              type="hidden"
            ></a-input>
            <a-upload
              name="file"
              list-type="picture"
              class="upload-list-inline"
              :fileList="fileList"
              :customRequest="changeImg"
              @change="getchangeImg"
              :remove="removeImg"
              :beforeUpload="handleBeforeUpload"
            >
              <a-button :disabled="!uploadBtn">
                <a-icon type="upload" /> 上传图片
              </a-button>
            </a-upload>
          </a-form-item>
          <a-form-item label="排序：">
            <a-input-number
              v-decorator="[
                'sort',
                {
                  rules: [{ required: true, message: '请输入排序!' }],
                },
              ]"
            />
          </a-form-item>
          <a-form-item label="是否可用">
            <a-radio-group
              v-decorator="[
                'status',
                {
                  rules: [{ required: true, message: '请选择是否可用!' }],
                },
              ]"
            >
              <a-radio :value="1"> 可用 </a-radio>
              <a-radio :value="0"> 不可用 </a-radio>
            </a-radio-group>
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
  </div>
</template>
<script>
export default {
  name: "Car",
  data() {
    return {
      color: "",
      color1: "",
      form: this.$form.createForm(this, { name: "deviceSearch" }),
      export: 0,
      addform: this.$form.createForm(this, { name: "city_add" }),
      columns: [
        {
          title: "轮播图ID",
          key: "id",
          dataIndex: "id",
          align: "center",
        },
        {
          title: "标题",
          key: "value.name.value",
          dataIndex: "value.name.value",
          align: "center",
        },
        {
          title: "链接",
          key: "value.url.value",
          dataIndex: "value.url.value",
          align: "center",
        },
        {
          title: "图片",
          key: "value.pic.value",
          dataIndex: "value.pic.value",
          align: "center",
          scopedSlots: { customRender: "pic" },
        },
        {
          title: "排序",
          key: "sort",
          dataIndex: "sort",
          align: "center",
        },
        {
          title: "是否可用",
          key: "status",
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
          align: "center",
        },
        {
          title: "操作",
          key: "btns",
          dataIndex: "btns",
          scopedSlots: { customRender: "btns" },
          align: "center",
        },
      ],
      visible: false,
      modelTitle: "",
      fileList: [],
      confirmLoading: false,
      uploadBtn: true,
      pageshow: false,
      currentPage: 1,
      total_num: 0,
      defaultPageSize: 10,
      pageSizeOptions: ["10", "20", "30", "50", "100"],
      table_h: "",
      posterList: [],
      canUpload: "",
      gid: 48,
    };
  },
  mounted() {
    window.addEventListener("resize", () => this.changeTableH(), false);
    this.getSearchList({});
  },
  methods: {
    changeTableH() {
      this.table_h =
        this.$refs.box.offsetHeight -
        this.$refs.form.offsetHeight -
        this.$refs.header.offsetHeight -
        45;
      if (this.table_h <= 250) {
        this.table_h = 400;
      }
    },
    //获取改变后的页码
    onPageChange(pageNumber) {
      var that = this;
      this.currentPage = pageNumber;
      that.form.validateFields((error, values) => {
        that.getSearchList(values);
      });
    },
    //获取每页条数
    onShowSizeChange(current, pageSize) {
      var that = this;
      this.defaultPageSize = pageSize;
      if (that.currentPage * pageSize - pageSize + 1 >= that.total_num) {
        that.currentPage = 1;
      }
      that.form.validateFields((error, values) => {
        that.getSearchList(values);
      });
    },
    //获取列表
    getSearchList(data) {
      var that = this;
    //   data.limit = that.defaultPageSize;
    //   data.page = that.currentPage;
      data.gid = that.gid;
      that.$message.loading({ content: "加载中..", key: "load",duration: 0 });
      that.pageshow = false;
      that.axios
        .get("/admin/systemGroupData/list", {
          params: data,
        })
        .then((res) => {
          that.$message.loading({
            content: "加载中..",
            key: "load",
            duration: 0.00001,
          });
          if (that.$code(res)) {
            var posterList = res.data.data;
            for (var i = 0; i < posterList.length; i++) {
              posterList[i].btns = ["编辑", "删除"];
            }
            that.changeTableH();
            that.posterList = posterList;
            // that.total_num = res.data.count;
            // that.currentPage = res.data.currentPage;
          }
        });

      that.$nextTick(() => {
        that.pageshow = true;
      });
    },
    //上传图片
    changeImg(info) {
      var that = this;
      const formData = new FormData();
      formData.append("file", info.file);
      that.$message.loading({ content: "加载中..", key: "load",duration: 0 });
      that.axios.post("/admin/upload/image", formData).then((res) => {
        that.$message.loading({
          content: "加载中..",
          key: "load",
          duration: 0.00001,
        });
        if (that.$code(res)) {
          var img = res.data.data.path_url;
          that.addform.setFieldsValue({
            pic: img,
          });
          that.fileList = [
            {
              uid: info.file.uid,
              name: info.file.name,
              status: "done",
              url: "" + img,
              thumbUrl: "" + img,
            },
          ];
        }
      });
    },
    //删除图片
    removeImg() {
      this.fileList = [];
    },
    //上传前校验
    handleBeforeUpload(file) {
      if (file.type.indexOf("image") < 0) {
        this.$message.error("仅支持上传图片");
        this.canUpload = false;
        return false;
      } else {
        this.canUpload = true;
        return true;
      }
    },
    getchangeImg(info) {
      if (!this.canUpload) {
        info.fileList = [];
      }
      if (info.fileList.length >= 1) {
        this.uploadBtn = false;
      } else {
        this.uploadBtn = true;
        if (info.fileList.length == 0) {
          this.addform.setFieldsValue({
            pic: "",
          });
        }
      }
    },
    //新增轮播图
    handleAdd() {
      this.visible = true;
      this.modelTitle = "新增轮播图";
      this.id = "";
      this.uploadBtn = true;
      this.fileList = [];
      var that = this;
      that.$nextTick(() => {
        that.addform.setFieldsValue({
          name: "",
          pic: "",
          url: "",
          sort: "",
          status: "",
        });
      });
    },
    //新增、编辑提交表单
    handleOk() {
      var that = this;
      that.confirmLoading = true;
      that.addform.validateFields((error, values) => {
        if (!error) {
          var data = values;
          if (!that.id) {
            that.axios
              .post("/admin/systemGroupData/save/48", data)
              .then((res) => {
                that.confirmLoading = false;
                //   that.axios.get("/Api/admin/city/edit",{params: data}).then((res)=>{
                if (that.$code(res)) {
                  that.getSearchList({});
                }
                that.visible = false;
              });
          } else {
            data.id = that.id;
            that.axios
              .post("/admin/systemGroupData/update/"+that.id, data)
              .then((res) => {
                that.confirmLoading = false;
                //   that.axios.get("/Api/admin/city/edit",{params: data}).then((res)=>{
                if (that.$code(res)) {
                  that.getSearchList({});
                }
                that.visible = false;
              });
          }
        } else {
          that.confirmLoading = false;
        }
      });
    },

    // 操作（删除、编辑）
    handel(btn, num) {
      var that = this;
    //   var data;
      that.id = num.id;
      if (btn == "编辑") {
        // data = {
        //   id: num.id,
        // };
        that.$message.loading({ content: "加载中..", key: "load",duration: 0 });
        that.axios
          .get("/admin/systemGroupData/getOne/"+num.id, {})
          .then((res) => {
            that.$message.loading({
              content: "加载中..",
              key: "load",
              duration: 0.00001,
            });
            if (that.$code(res)) {
                console.log(res)
              that.uploadBtn = false;
              that.fileList = [
                {
                  uid: "-1",
                  name: "",
                  status: "done",
                  url: res.data.data.value.pic.value,
                  thumbUrl: res.data.data.value.pic.value,
                },
              ];
              that.visible = true;
              that.modelTitle = "编辑轮播图";
              that.$nextTick(() => {
                that.addform.setFieldsValue({
                  name: res.data.data.value.name.value,
                  pic: res.data.data.value.pic.value,
                  url: res.data.data.value.url.value,
                  sort: res.data.data.sort,
                  status: res.data.data.status,
                });
              });
            }
          });
      }
      if (btn == "删除") {
        that.$confirm({
          title: "提示",
          content: "是否" + btn + "此轮播图",
          okText: "确认",
          cancelText: "取消",
          onOk() {
            that.$message.loading({ content: "加载中..", key: "load",duration: 0 });
            // data = {
            //   id: num.id,
            // };
            that.axios
              .delete("/admin/systemGroupData/del/" + num.id, {})
              .then((res) => {
                that.$message.loading({
                  content: "加载中..",
                  key: "load",
                  duration: 0.00001,
                });
                if (that.$code(res)) {
                  that.$message.success(btn + "成功");
                  that.form.validateFields((error, values) => {
                    that.getSearchList(values);
                  });
                }
              });
          },
        });
      }
    },

    //筛选 导出设备
    getExport(e) {
      this.export = e;
    },
    handleSubmit(e) {
      if (e) {
        e.preventDefault();
      }
      var that = this;
      that.form.validateFields((error, values) => {
        if (that.export == 0) {
          that.currentPage = 1;
          // this.$refs.pagination.internalCurrentPage = this.nowpage;
          that.getSearchList(values);
        } else {
          var data = "";
          for (var key in values) {
            if (values[key]) {
              data += "&" + key + "=" + values[key];
            }
          }
          var myObj = {
            method: "get",
            url: "/" + that.pageNowUrl + "?export=1" + data,
            fileName: "设备列表.csv",
          };
          this.$exportMethod(myObj);
        }
      });
    },
  },
};
</script>
<style scoped>
table img {
  width: 100px;
}
.ant-table-wrapper {
  flex: 1;
}
.ant-modal-body form {
  overflow: hidden;
}
.ant-input-number {
  width: 100%;
}
.m-colorPicker {
  position: absolute;
  height: 39px;
  right: 0;
  width: 100%;
}
/deep/.m-colorPicker .colorBtn {
  margin-top: 8px;
  position: absolute;
  right: 9px;
  top: 5px;
}
/deep/.m-colorPicker .box {
  right: 0;
}
</style>